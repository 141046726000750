@import "../../../App.scss";

.New-Tab {
    overflow-y: clip;
}

.App {
    display: grid;
    grid-template-columns: min-content;
    justify-content: center;
}

.Foreground {
    text-align: center;
}

.Greeting {
    width: 100%;
}

.Clock {
    margin-top: 15%;
    margin-bottom: 0;
}

.Settings {
    margin: 1%;
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 1;
}

.Prayer-Times {
    margin: 1% 2% 1% 1%;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 2;
}

@media only screen and (max-width: $mobile-screen-width) {
    .Prayer-Times {
        margin: 5% 8% 1% 1%;
    }
}

.Background {
    position: absolute;
    top: -40vh;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    color: white;
    background-size: cover;
}