.Header {
    text-align: center;
    color: white;
}

.Content {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    overflow: hidden;
}

.About {
    background-image: radial-gradient( circle farthest-corner at 10% 20%,  rgba(0,152,155,1) 0.1%, rgba(0,94,120,1) 94.2% );
    padding-bottom: 5vh;
    padding-top: 40vh;
}