@import "../../../App.scss";

.Quote {
    position: fixed;
    left: 50%;
    bottom: 1%;
    transform: translate(-50%, -50%);
    white-space: nowrap;
    font-size: 1.2rem;
    font-weight: 300;
    text-align: center;
}

@media only screen and (max-width: $mobile-screen-width) {
    .Quote {
        display: none;
    }
}