@import "../../../App.scss";

.Search-Input {
    border-bottom: 1px black solid;
    border-top: none;
    border-left: none;
    border-right: none;
    background-color: rgba(255,255,255, 0.6);
    border-radius: 5px;
    font-size: 1.1rem;
    padding: 10px;
    width: 30vw;
}

.Search-Input:focus{
    outline: none;
    background-color: rgba(255,255,255, 1);
}

@media only screen and (max-width: $mobile-screen-width) {
    .Search-Input {
        width: 70vw;
    }
}

.Search-Input::placeholder {
    color: rgba(0,0,0, 1);
}