.Button-Group {
    font-weight: 300;
    font-size: .9rem;
    margin-top: 5vh;
    align-items: center;
}

.Button-Group-Item {
    margin: 25px 0 5px -5px;
    padding: 5px 0 5px 5px;
    background-color: rgba(255, 255, 255, 0.1);
    border: rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    text-align: left;
    width: 40px;
    margin-right: 260px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.Button-Group-Item:hover {
    background-color: rgba(255, 255, 255, 0.5);
    border: rgba(255, 255, 255, 0.5);
}
