.Results {
    font-weight: 300;
    font-size: .9rem;
    margin-top: 5vh;
    align-items: center;
}

.Result-Item {
    margin: 5px 0 5px 0;
    padding: 5px 0 5px 5px;
    background-color: rgba(0, 0, 0, 0.2);
    border: rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    text-align: left;
    width: 300px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.Result-Item:hover {
    background-color: rgba(0, 0, 0, 0.6);
    border: rgba(0, 0, 0, 0.6);
}


.Close {
    background-color: rgba(0, 0, 0, 0.0) !important;
    margin: 20px 0 0 0;
    text-align: right;
    width: 300px;
    font-weight: 300;
    font-size: .9rem;
}