.Search-Button {
    padding: 10px;
    border: none;
    margin: 0 0 0 10px;
    font-size: 1rem;
    border-radius: 5px;

}

.Search-Button:active {
    background-color: rgba(255,255,255, 0.5);
}