.SelectInput {
    display: grid;
    grid-template-columns: 40% 60%;
    justify-content: center;
    font-weight: 300;
    margin: 10px 0 0 0;
}

.Select {
    width: max-content;
    background-color: rgba(255, 255, 255, 0.8);
    border: none;
    border-radius: 5px;
    margin-bottom: -4px;
    width: 85%;
}