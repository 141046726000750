@import "../../../App.scss";

.Prayer-Panel {
    color: white;
    background-color: rgba(0, 0, 0, .75);
    border: rgba(0, 0, 0, .1);
    border-radius: 10px;
    padding: 1% 1% 3% 2%;
    user-select: none;
}

@media only screen and (max-width: $mobile-screen-width) {
    .Prayer-Panel {
        position: absolute;
        padding-left: 10px;
    }
  }

.Prayer-Times-Toggle {
    background-color: rgba(0, 0, 0, .2);
    width: 100%;
    border-radius: 10px;
    padding: 5%;
}


.Prayer-Times-Toggle:hover {
    background-color: rgba(0, 0, 0, .4);
}

.Set-Location {
    background-color: rgba(0, 0, 0, .2);
    width: 100%;
    border-radius: 10px;
    padding: 5%;
}