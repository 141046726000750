.Recommendations {
    text-align: left;
    padding: 1px 0 1px 0;
    background-color: rgba(255,255,255, .5);
    border: none;
    border-radius: 5px;
    color: black;
}

.Recommendations-Focused {
    background-color: rgba(255,255,255, 1);
}

.Recommendation {
    margin: 5px;
    padding: 10px;
}

.Recommendation:hover {
    background-color: rgba(0,0,0, 0.1);
    border-radius: 5px;
    cursor: pointer;
}

