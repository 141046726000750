.scroll {
    animation: pulse 1s infinite;
    animation-direction: alternate;
    transform: rotate(180deg);
}

@keyframes pulse {
    0% {
        color: white;
    }

    100% {
        color: black;
    }
}