.Cog {
    user-select: none;
    font-size: 1rem;
    color: black;
    opacity: .5;
    transition: opacity 0.1s ease-in-out;
}

.Cog:hover {
    opacity: 1;
    color: white;
	filter: alpha(opacity=10);
}

.Cog:not(:hover) {
    color: white;
}