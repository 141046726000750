@import "../../../App.scss";

.Greeting {
    text-align: center;
    font-size: 2.1rem;
    width: auto;
    overflow: hidden;
    white-space: nowrap;
}



@media only screen and (max-width: $mobile-screen-width) {
    .Greeting {
        white-space: normal;
        font-weight: 300;
        width: 100vw;
        height: 110px;
    }
}

.Fade-In {
    animation: fadeIn linear 1s;
}

.Fade-Out {
    animation: fadeOut linear 1s;
}

.Hide {
    visibility: hidden;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}