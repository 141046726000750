$row-hover-color: rgba(255, 255, 255, 0.1);
$row-current-prayer: rgba(rgb(218, 240, 204), 0.5);
$row-next-prayer: rgba(rgb(240, 204, 204), 0.5);

.Prayer-Timetable {
    background-color: rgba(0, 0, 0, 0.6);
    border-radius: 10px;
    margin: 10% 10% 0 0;
}

.Table-Row {
    padding: 1% 0 1% 5%;
    display: grid;
    grid-template-columns: 40% 60%;
    justify-content: center;
}

.Table-Row:hover {
    background-color: $row-hover-color;
    border-radius: 10px;
}

.Event-Name {
    text-align: left;
}

.Event-Time {
    text-align: left;
    margin-left: 5%;
}

.Current-Prayer-Row {
    background-color: $row-current-prayer;
}

.Current-Prayer-Row:hover {
    background-color: $row-hover-color;
}

.Next-Prayer-Row {
    background-color: $row-next-prayer;
}