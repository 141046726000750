@import "../../../App.scss";

.Credit {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    color: white;
    text-align: left;
    margin: 1%;
    opacity: 0.2;
    font-size: 0.7rem;
    transition: opacity 0.1s ease-in-out;
}

.Credit:hover {
    opacity: 1;
	filter: alpha(opacity=10);
}

@media only screen and (max-width: $mobile-screen-width) {
    .Credit {
        display: none;
    }
}

.Image {
    display:block;
    width:100%; height:110%;
    object-fit: cover;
    color: red;
}

.Add-Blur {
    filter: blur(3px);
    transition: 0.3s filter ease-in-out;
}

.Remove-Blur {
    filter: blur(0px);
    transition: 0.3s filter ease-in-out;
}

.Fade-In {
    animation: fadeIn linear 0.2s;
}

.Hide {
    visibility: hidden;
}

@keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
}
