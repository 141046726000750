@import "../../../App.scss";

.Header {
    font-weight: 300;
}

.Settings-Panel {
    color: white;
    background-color: rgba(0, 0, 0, .75);
    border: rgba(0, 0, 0, .1);
    border-radius: 10px;
    padding: 1vh 1vw 3vh 1vw !important;
    user-select: none;
}

@media only screen and (max-width: $mobile-screen-width) {
    .Settings-Panel {
        bottom: 40% !important;
        right: 85% !important;
    }
}

.Divider {
    border: none;
    height: 1px;
    margin: -5px 0 10px 0; 
    background-color: rgba(255, 255, 255, 0.1);
}

.Settings-Item {
    padding-top: 5px;
}

.Settings-Done {
    margin-left: 1% !important;
}